html {
    height: 100%;
    overflow-x: hidden;
    background-color: white;
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
